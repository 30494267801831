import React from 'react';
import UpperAnimation from '../UpperAnimation/UpperAnimation';
import Midder from '../Midder/Midder';
import Services from '../Services/Services';
import Thirder from '../Thirder/Thirder';

const Home = () => {
  return (
    <div>
      <UpperAnimation />
      <Thirder></Thirder>
      <Services></Services>
      {/* <Midder></Midder> */}
      <br></br>
    </div>
  );
};

export default Home;