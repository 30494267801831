import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import robot from '../../images/map.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Services = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: robot,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);
  return (
    <div>
      <Container className="my-4 fluid">
        <center>
        <hr style={{ opacity: "10%", textAlign: "Center", width: "40%" }}></hr>
        </center>
        <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat", textAlign: "center" }}>Our Markets</h3><br></br>
        <br></br>
        <Container className="my-4 fluid">
          <Row className="justify-content-md-center align-items-center" >
            <Col sm={5}> <div ref={animationContainer}></div></Col>
            <Col sm={6}>
              <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
                A key strength of SMARTMATRIX lies in developing a robust multi-continental collaboration network. Currently, we are actively engaged in numerous countries and regions, primarily focusing on:<br></br><br></br>
                <ul>
                  <li>The Middle East (UAE, Qatar)</li>
                  <li>Europe (France, Germany, Poland, Romania, Bulgaria)</li>
                  <li>Europe (France, Germany, Poland, Romania, Bulgaria)</li>
                  <li>South Asia (Bangladesh, India, Nepal)</li>
                  <li>East Asia (China, Indonesia, Thailand, Vietnam)</li>
                  <li>North America (USA)</li>
                </ul> <br></br>
                Our extensive global network of suppliers ensures our customers have access to a diverse selection of superior products. Backed by a team of dedicated professionals, we are committed to delivering exceptional service, swift shipping, and competitive pricing. Rest assured; we will provide the ideal combination of market prices and superior quality tailored to your specifications and packaging requirements.	    	
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <br></br><br></br>
    </div>
  );
};

export default Services;