import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import animationData from '../../images/solutions.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import smartcard from '../../images/wheat.json'
import Image from 'react-bootstrap/Image';
import home from '../../images/home.json';
import ebook from '../../images/drill.json';
import industry from '../../images/coal.json';
import health from '../../images/rice.json';
import rfid from '../../images/sugar.json';
import commodity from '../../images/commodity.json';
import ecommerce from '../../images/ecommerce.json';
import coffee from '../../images/coffee.json';
import cons from '../../images/cons.json';
import power from '../../images/epower.json';
import gas from '../../images/gas.json';
import agrico from '../../images/agrico.json';
import ferti from '../../images/fertilizers.json';
import cement from '../../images/Cement.json';


const Thirder = () => {
  const animationContainer = useRef(null);
  const cardContainer = useRef(null);
  const bookContainer = useRef(null);
  const homeContainer = useRef(null);
  const industryContainer = useRef(null);
  const healthContainer = useRef(null);
  const rfidContainer = useRef(null);
  const ecommerceContainer = useRef(null);
  const commodityContainer = useRef(null);
  const coffeeContainer = useRef(null);
  const construction = useRef(null);
  const powerCons = useRef(null);
  const agriCo = useRef(null);
  const Fertiz = useRef(null);
  const gasT = useRef(null);
  const Cement = useRef(null);


  useEffect(() => {
    const anim301 = lottie.loadAnimation({
      container: gasT.current,
      animationData: gas,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim301.destroy();
    };
  }, []);



  useEffect(() => {
    const anim302 = lottie.loadAnimation({
      container: Cement.current,
      animationData: cement,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim302.destroy();
    };
  }, []);




  useEffect(() => {
    const anim100 = lottie.loadAnimation({
      container: construction.current,
      animationData: cons,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim100.destroy();
    };
  }, []);


  useEffect(() => {
    const anim201 = lottie.loadAnimation({
      container: Fertiz.current,
      animationData: ferti,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim201.destroy();
    };
  }, []);


  useEffect(() => {
    const anim103 = lottie.loadAnimation({
      container: agriCo.current,
      animationData: agrico,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim103.destroy();
    };
  }, []);



  useEffect(() => {
    const anim101 = lottie.loadAnimation({
      container: powerCons.current,
      animationData: power,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim101.destroy();
    };
  }, []);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);



useEffect(() => {
    const anim41 = lottie.loadAnimation({
      container: coffeeContainer.current,
      animationData: coffee,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim41.destroy();
    };
  }, []);


  useEffect(() => {
    const anim16 = lottie.loadAnimation({
      container: commodityContainer.current,
      animationData: commodity,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim16.destroy();
    };
  }, []);


  useEffect(() => {
    const anim1 = lottie.loadAnimation({
      container: cardContainer.current,
      animationData: smartcard,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim1.destroy();
    };
  }, []);


  useEffect(() => {
    const anim2 = lottie.loadAnimation({
      container: bookContainer.current,
      animationData: ebook,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim2.destroy();
    };
  }, []);


  useEffect(() => {
    const anim3 = lottie.loadAnimation({
      container: homeContainer.current,
      animationData: home,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim3.destroy();
    };
  }, []);



  useEffect(() => {
    const anim4 = lottie.loadAnimation({
      container: industryContainer.current,
      animationData: industry,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim4.destroy();
    };
  }, []);


  useEffect(() => {
    const anim5 = lottie.loadAnimation({
      container: healthContainer.current,
      animationData: health,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim5.destroy();
    };
  }, []);


  useEffect(() => {
    const anim6 = lottie.loadAnimation({
      container: rfidContainer.current,
      animationData: rfid,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim6.destroy();
    };
  }, []);


  useEffect(() => {
    const anim6 = lottie.loadAnimation({
      container: ecommerceContainer.current,
      animationData: ecommerce,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim6.destroy();
    };
  }, []);

  return (
    <div>
      <Container >
        <center>  <hr style={{ opacity: "10%", textAlign: "Center", width: "40%" }}></hr></center>
        <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat", textAlign: "center" }}>Business and Products</h3><br></br>
        <Row>
          <Col md={12}>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}><br></br><br></br>
              At SMARTMATRIX, we take immense pride in our ability to source premium items that perfectly meet the unique requirements of our esteemed clientele. Our professional team has expertise in navigating the complexities of global commerce and logistics, employing various modes of transportation, including air, sea, and land, to ensure swift and efficient delivery.
              <span>We specialize in the following product categories:</span><br></br><br></br>
            </p>
            <p></p>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col md={7}>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}> <span style={{ fontWeight: "500" }}><h5 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>1. Construction Goods:</h5></span>
              Discover an extensive range of construction goods meticulously sourced from reputable manufacturers who adhere to our stringent quality standards. We understand that in construction, quality is non-negotiable. Therefore, we only stock reliable, durable, and long-lasting products. Alongside our comprehensive selection, we offer fast and dependable shipping services. Recognizing the time-sensitive nature of the construction industry, our aim is to expedite the delivery of the products you require with utmost efficiency.
              Our construction goods encompass a wide array of quality products catering to the demands of any construction project. 
              <br></br><br></br>
              From fundamental materials to advanced building solutions, our offerings include:
              <ul>
                <li>Cement</li>
                <li>Crushed Stone</li>
                <li>Steel</li>
                <li>Clinker</li>
              </ul>
              </p>
          </Col>
          <Col md={5}>
            <div ref={construction}></div>
          </Col>
        </Row>
       <br></br><br></br>



        <Row>
          <Col md={5}>
            <div ref={powerCons}></div>
          </Col>
          <Col md={7}>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}> <span style={{ fontWeight: "500" }}><h5 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>2. Non-Renewable Energy Source:</h5></span>
              As a trusted source for non-renewable energy products, SMARTMATRIX presents a curated collection of environmentally friendly energy solutions. Our team of experts meticulously selects products from top-tier manufacturers to ensure unparalleled quality. With a diverse range of offerings, we supply an array of non-renewable energy products that are rigorously monitored and tested to meet the highest standards of quality, safety, and environmental impact. Our experts can assist you in identifying the optimal energy solution for your needs, providing guidance throughout the installation and maintenance process. 
              <br></br><br></br>
              Our non-renewable energy product range includes:
              <ul>
                <li>Coal</li>
                <div>
                  
                </div>
                <li>Natural gas</li>
              </ul>
              
              We also offer a comprehensive range of Petroleum products,<br></br> which encompasses:
              <ul>
                <li>Diesel</li>
                <li>Jet Fuel</li>
                <li>Gasoline</li>
                <li>Naphtha</li>
                </ul>
            </p>
          </Col>
        
        </Row>

        <Row>
          <Col md={7}>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}> <span style={{ fontWeight: "500" }}><h5 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>3. Agricultural Products:</h5></span>
              At SMARTMATRIX, we are committed to deliver the finest quality agricultural products that fuel the growth of your business. Our offerings are sourced from industry-leading manufacturers, ensuring unparalleled quality across our product range. Our agricultural products empower farmers to increase yield and maximize profits. Explore our website to discover a wide assortment of agricultural products, including grains, fruits, vegetables, and livestock. Our selection includes:
              <br></br><br></br>
              Crop Items:
              <ul>
                <li>Corn</li>
                <li>Sugar</li>
                <li>Soybean Oil</li>
                <li>Red Lentil</li>
                <li>Wheat</li>
              </ul>
            </p>
          </Col>
          <Col md={5}>
            <div ref={agriCo}></div>
          </Col>
        </Row>
        <br></br><br></br>



        <Row>
          <Col md={5}>
            <div ref={Fertiz}></div>
          </Col>
          <Col md={7}>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}> <span style={{ fontWeight: "500" }}><h5 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>4. Fertilizer:</h5></span>
              Our supplied fertilizers are carefully formulated with high-quality ingredients, meticulously blended to provide the perfect balance of nutrients for your plants. As a leading supplier of different fertilizers, we offer a highly effective and affordable range of fertilizers for crops. Our team of experts ensures the highest quality standards for all our fertilizers, conducting thorough purity tests to ensure it is free from any harmful contaminants. Our fertilizers are suitable for all types of crops, including fruits, vegetables, grains, and more. It thrives in various soil types and conditions, from sandy to clay-based soils.
              <br></br><br></br>
              Fertilizer Items:
              <ul>
                <li>MOP</li>
                <li>DAP</li>
                <li>Urea and others</li>
              </ul>
            </p>
          </Col>

         </Row><br></br><br></br>
        <center>  <hr style={{ opacity: "10%", textAlign: "Center", width: "40%" }}></hr></center>
        <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat", textAlign: "center" }}>Commodity Lines</h3><br></br><br></br><br></br>

        <Row className='justify-content-md-center align-items-center' style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat"}} >
          <Col md={1}></Col>
          <Col md={2} >
            <h6 style={{fontWeight: "560"}}>Wheat Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
          </Col>
          <Col md={2}>
            <div ref={cardContainer}></div>
          </Col>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Gas Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
          </Col>
          <Col md={2}>
            
            <div ref={gasT}></div>
          </Col>
          <Col md={1}></Col>
        </Row>


        <Row className='justify-content-md-center align-items-center' style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Coffee Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
           
          </Col>
          <Col md={2}>
            <div ref={coffeeContainer}></div>
          </Col>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Coal Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
            
          </Col>
          <Col md={2}>
            <div ref={industryContainer}></div>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row className='justify-content-md-center align-items-center' style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Rice Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
          </Col>
          <Col md={2}>
            <div ref={healthContainer}></div>
          </Col>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Sugar Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
            
          </Col>
          <Col md={2}>
            <div ref={rfidContainer}></div>
          </Col>
          <Col md={1}></Col>
        </Row><br></br>

        <Row className='justify-content-md-center align-items-center' style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Edible Oil Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>
          </Col>
          <Col md={2}>
            <div ref={bookContainer}></div>
          </Col>
          <Col md={1}></Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "560" }}>Cement Supply</h6>
            <a href="/service"><FontAwesomeIcon icon={faArrowRight} style={{ color: "367efd" }} /></a>

          </Col>
          <Col md={2}>
            <div ref={Cement}></div>
          </Col>
          <Col md={1}></Col>

        </Row>
      </Container>
      <br></br><br></br>
    </div>
  );
};

export default Thirder;