
import React, { useEffect, useRef } from 'react';
import Head from '../Head/Head';
import lottie from 'lottie-web';
import animationData from '../../images/aboutus.json';
import goal from '../../images/goal.json';
import vision from '../../images/vision.json'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Aboutus = () => {
  const animationContainer = useRef(null);
  const visionContainer = useRef(null);
  const goalContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);



  useEffect(() => {
    const anim1 = lottie.loadAnimation({
      container: visionContainer.current,
      animationData: vision,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim1.destroy();
    };
  }, []);


  useEffect(() => {
    const anim2 = lottie.loadAnimation({
      container: goalContainer.current,
      animationData: goal,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim2.destroy();
    };
  }, []);



  return (
    <div>
      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>ABOUT US</h3>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>Professionals with huge experience and wide exposure in Communications and Information Technology are the backbone of SmartMatrix. People involved here are young qualified business graduates and qualified engineers from renowned universities across the globe. </p>
          </Col>
          <Col sm={6}> <div ref={animationContainer}></div></Col>
        </Row>
      </Container>

<br></br>

      <Container className="my-4 fluid">

        <Row className="justify-content-md-center align-items-center" >
          <Col sm={5}> <div ref={goalContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Our Mission</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              Our mission is to manufactures electronic products and Software Development for both domestic and international market by continually improving our process, utilizing quality practices, employee skill development that result in customer and employee satisfaction.
            </p>
          </Col>

        </Row>
      </Container>

 <br></br>     
      <Container className="my-4 fluid">
        
        <Row className="justify-content-md-center align-items-center" >
         
          <Col sm={7}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Our Vision</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              We will provide competitive, high quality electronic manufacturing services, developing softweareand individualized customer 
              service, while encouraging employee creativity, motivation and teamwork 
              in a continually improving environment. We will solve today's challenges and enable tomorrows technology.
            </p>
          </Col>
          <Col sm={4}> <div ref={visionContainer}></div></Col>
          
        </Row>
      </Container>


    </div>
  );
};

export default Aboutus;