import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import animationData from '../../images/network.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import smartcard from '../../images/sugar.json'
import Image from 'react-bootstrap/Image';
import home from '../../images/home.json';
import electric from '../../images/drill.json'
import ebook from '../../images/wheat.json';
import industry from '../../images/industry.json';
import health from '../../images/coal.json';
import rfid from '../../images/rfid.json';
import ecommerce from '../../images/ecommerce.json';
import support from '../../images/coffee.json';
import erp from '../../images/rice.json';
import software from '../../images/software.json';
import commodity from '../../images/commodity.json';


const Service = () => {

  const animationContainer = useRef(null);
  const cardContainer = useRef(null);
  const bookContainer = useRef(null);
  const electricContainer = useRef(null);
  const homeContainer = useRef(null);
  const industryContainer = useRef(null);
  const healthContainer = useRef(null);
  const rfidContainer = useRef(null);
  const ecommerceContainer = useRef(null);
  const supportContainer = useRef(null);
  const erpContainer = useRef(null);
  const softwareContainer = useRef(null);
  const commodityContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);


  useEffect(() => {
    const anim20 = lottie.loadAnimation({
      container: commodityContainer.current,
      animationData: commodity,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim20.destroy();
    };
  }, []);


  useEffect(() => {
    const anim12 = lottie.loadAnimation({
      container: softwareContainer.current,
      animationData: software,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim12.destroy();
    };
  }, []);

  useEffect(() => {
    const anim11 = lottie.loadAnimation({
      container: erpContainer.current,
      animationData: erp,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim11.destroy();
    };
  }, []);


  useEffect(() => {
    const anim10 = lottie.loadAnimation({
      container: supportContainer.current,
      animationData: support,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim10.destroy();
    };
  }, []);


  useEffect(() => {
    const anim1 = lottie.loadAnimation({
      container: cardContainer.current,
      animationData: smartcard,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim1.destroy();
    };
  }, []);


  useEffect(() => {
    const anim2 = lottie.loadAnimation({
      container: bookContainer.current,
      animationData: ebook,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim2.destroy();
    };
  }, []);


  useEffect(() => {
    const anim3 = lottie.loadAnimation({
      container: homeContainer.current,
      animationData: home,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim3.destroy();
    };
  }, []);



  useEffect(() => {
    const anim4 = lottie.loadAnimation({
      container: industryContainer.current,
      animationData: industry,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim4.destroy();
    };
  }, []);


  useEffect(() => {
    const anim5 = lottie.loadAnimation({
      container: healthContainer.current,
      animationData: health,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim5.destroy();
    };
  }, []);


  useEffect(() => {
    const anim6 = lottie.loadAnimation({
      container: rfidContainer.current,
      animationData: rfid,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim6.destroy();
    };
  }, []);


  useEffect(() => {
    const anim6 = lottie.loadAnimation({
      container: ecommerceContainer.current,
      animationData: ecommerce,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim6.destroy();
    };
  }, []);



  useEffect(() => {
    const anim9 = lottie.loadAnimation({
      container: electricContainer.current,
      animationData: electric,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim9.destroy();
    };
  }, []);


  return (
    <div>
      <Container className="fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Our Commitment to the Services</h3><br></br><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a commodity solution provider that is committed to delivering high-quality services to its customers. They strive to meet the needs and expectations of their clients by offering reliable and efficient solutions. SmartMatrix ensures that their services are tailored to the specific requirements of each customer, providing a customized approach to address individual business challenges.<br></br>

              {/* Their commitment to service includes:
             <ul>
                <li>Reliability: SmartMatrix understands the importance of uninterrupted service. They aim to deliver a reliable solution that is available and operational when needed, minimizing downtime and ensuring consistent performance.</li>

               <li>Quality: SmartMatrix focuses on delivering a high-quality commodity solution. They adhere to industry standards and best practices to provide a robust and dependable service.</li>

                <li>Customization: SmartMatrix recognizes that different businesses have unique requirements. They work closely with clients to understand their needs and tailor their solutions accordingly, providing a customized approach to meet specific objectives.</li>

                <li>Support: SmartMatrix is dedicated to providing excellent customer support. They offer ongoing assistance, troubleshooting, and maintenance to ensure a smooth experience for their clients. They have a responsive support team that is available to address any concerns or issues promptly.</li>

                <li>Scalability: SmartMatrix's solutions are designed to scale as the business grows. They provide flexible and scalable options that can accommodate changing requirements and expanding operations.</li>
              </ul> */}
            </p>
          </Col>
          <Col sm={6}> <div ref={animationContainer}></div></Col>
        </Row>
      </Container>

      <Container className="my-4 fluid">
        <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat", textAlign: "center" }}>Our Commodities</h3><br></br><br></br>
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={5}> <div ref={cardContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Sugar Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent player in the global sugar industry, operating as a large-scale supplier across multiple countries including Brazil, the United States, and various other regions. With a focus on sourcing, processing, and distribution, SmartMatrix caters to the demands of diverse industries and consumers worldwide. Leveraging strong partnerships and industry expertise, they ensure a reliable supply of high-quality sugar products to meet the specific requirements of their global customer base.
            </p>
          </Col>
        </Row>
      </Container>


      <br></br>

      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >
        
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Wheat Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent global wheat supplier, operating in multiple countries including Canada, the United States, Australia, Argentina, Russia, and Ukraine. With an extensive network of farmers, grain merchants, and agricultural cooperatives, SmartMatrix sources high-quality wheat from these regions. They offer a wide range of wheat varieties to meet the diverse needs of their customers worldwide. Leveraging their expertise and strong partnerships, SmartMatrix ensures a reliable supply of premium wheat products to the global market.
            </p>
          </Col>
          <Col sm={5}> <div ref={bookContainer}></div></Col>
        </Row>
      </Container>

      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={5}> <div ref={electricContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Edible Oil Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent supplier of edible oils, offering a diverse range of options to meet various culinary needs. Their product line includes popular oils such as soybean oil, mustard oil, sunflower oil, and many others. With a commitment to quality and customer satisfaction, SmartMatrix ensures that their oils are sourced from reliable and trusted producers. They prioritize the provision of healthy and nutritious oils, aiming to enhance the culinary experiences of individuals and households. As a leading supplier in the industry, SmartMatrix has gained a reputation for delivering high-quality edible oils to meet the demands of both domestic and commercial consumers.
            </p>
          </Col>
        </Row>
      </Container>



      <br></br>

      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >

          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Coal Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent coal supplier operating globally, sourcing coal from key regions such as the United States, China, Australia, Indonesia, and South Africa. With strong partnerships established with coal producers and mining companies, they ensure a consistent supply of high-quality coal. Adhering to stringent quality control measures, SmartMatrix meticulously assesses coal quality parameters such as calorific value, moisture content, ash content, and sulfur content. Their well-established distribution network enables efficient transportation and timely delivery of coal to customers worldwide. By understanding the specific requirements of industries such as power generation, steel production, and cement manufacturing, SmartMatrix offers tailored coal solutions to meet diverse customer needs. While actively participating in the coal industry, SmartMatrix remains committed to promoting sustainability, investing in technologies and initiatives to improve energy efficiency and reduce environmental impact associated with coal mining and usage. Through their expertise, reliability, and dedication to sustainability, SmartMatrix plays a crucial role in meeting global coal demands.
            </p>
          </Col>
          <Col sm={5}> <div ref={healthContainer}></div></Col>
        </Row>
      </Container>

      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={5}> <div ref={supportContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Coffee Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent global coffee supplier, sourcing high-quality coffee beans from renowned coffee-growing regions such as Brazil, Colombia, Ethiopia, Vietnam, and Costa Rica. With stringent quality control measures in place, they carefully select and assess the beans based on factors like origin, variety, altitude, and flavor profile. Utilizing a well-developed distribution network, SmartMatrix efficiently transports and delivers coffee to their diverse customer base, including cafes, restaurants, hotels, and individual coffee enthusiasts. They offer a wide range of coffee options tailored to meet specific preferences and requirements. Committed to sustainability, SmartMatrix supports fair trade, organic farming, and initiatives that benefit coffee-growing communities. With their expertise, dedication to quality, and sustainable practices, SmartMatrix plays a significant role in the global coffee supply chain, providing exceptional coffee experiences to customers worldwide.
              
              
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >

          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Rice Supply</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              SmartMatrix is a prominent global rice supplier, sourcing high-quality rice from major rice-producing countries such as India, China, Thailand, Vietnam, and the United States. With a focus on quality control, they carefully select and evaluate rice based on grain size, texture, aroma, and cooking characteristics. Their efficient distribution network ensures timely delivery, preserving the freshness and quality of the rice. SmartMatrix offers a wide variety of rice options to meet diverse culinary preferences, including long-grain, short-grain, jasmine, basmati, and specialty rice. Committed to sustainability, they support responsible farming practices to promote sustainable rice production. As a trusted rice supplier, SmartMatrix plays a crucial role in meeting global rice demands, providing customers with superior rice products worldwide.
            </p>
          </Col>
          <Col sm={5}> <div ref={erpContainer}></div></Col>
        </Row>
      </Container>


      <br></br>
{/* 
      <Container className="my-2 fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={5}> <div ref={softwareContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Customized Software Development</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
             Full-cycle software development services designed to help your businessgrow Whether it’s custom software engineering, software testing and quality assurance, system integration, technology consulting, or software support — we got you covered. We offer a powerful combination of profound tech expertise, mature, low-risk processes, and proven experience in a variety of business domains to turn your business idea into reality.
            </p>
          </Col>
        </Row>
      </Container> */}

      {/* <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >

          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Commodity Solutions</h3><br></br>
            <p style={{ fontWeight: '200', alignItems: "center", fontFamily: "Montserrat", textAlign: "justify" }}>
              Our team of seasoned professionals understands the unique challenges and intricacies of the commodity market. We work closely with our clients to tailor solutions that address their specific needs, whether it's risk management, supply chain optimization, or trading strategies. By leveraging our deep industry knowledge and innovative tools, we empower businesses to make informed decisions and seize lucrative opportunities.

              At Best Commodity Solutions, we prioritize efficiency and transparency. Our cutting-edge technology platforms enable seamless integration and real-time data analysis, allowing our clients to monitor market trends, track inventory, and streamline operations. With access to accurate and timely information, businesses can enhance their decision-making capabilities and gain a competitive edge.
            </p>
          </Col>
          <Col sm={5}> <div ref={commodityContainer}></div></Col>
        </Row>
      </Container> */}


    </div>
  );
};

export default Service;