
import React, { useState, useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import { useForm } from 'react-hook-form';
import animationData from '../../images/jobs.json';
import done from '../../images/done.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';

const Job = () => {
  const [send, setSend] = useState(false);
  const [bt, setBt] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    area: '',
    file: null
  });


  const animationContainer = useRef(null);
  const doneContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: doneContainer.current,
      animationData: done,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);


  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, file: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    setBt(false);
    event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('area', formData.area);
    formDataToSend.append('file', formData.file);

    axios.post('https://api.smartmatrix.life/api/contact/', formDataToSend, {
      headers: {
        'Token': "61748#910388%3@-292$",
      }})
      .then(response => {
        // Handle successful response
       
        setSend(true);
        console.log(response.data);
        window.location.reload();
        
      })
      .catch(error => {
        // Handle error
        console.error(error);

      });
  };

  return (

    !send ? <div> <Container className="my-4 fluid">
        <Row className="justify-content-md-center align-items-center" >
          <Col sm={6}> <div ref={animationContainer}></div></Col>
          <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>Drop Your CV!</h3><br></br>
          <Form onSubmit={handleSubmit} style={{
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            padding: '30px',
            fontFamily: "montserrat",
            fontWeight: "600",
}}>
            <Form.Label>Your Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              /><br></br>

            <Form.Label>Your Email</Form.Label>
            <Form.Control
                required
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              /><br></br>

            <Form.Label>Your Preferred Area</Form.Label>
            <Form.Control
                required
                type="text"
                name="area"
                value={formData.area}
                onChange={handleInputChange}
              /><br></br>

            <Form.Label>Upload CV</Form.Label>
            <Form.Control
                required
                type="file"
                name="file"
                onChange={handleFileChange}
              /><br></br>

            {
              bt ? <Button type="submit">Submit</Button> : <div className="d-flex justify-content-center align-items-center">
                <p>Uploading Please Wait...🚀</p>
                <Spinner animation="border" className="spinner-border text-primary"  role="status" >
                 
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }
        
            </Form>
          </Col>
        </Row>
       
      </Container><br></br>
    
    </div> : <div> <Container className="my-4 fluid">
      <Row className="justify-content-md-center align-items-center" >
          <Col sm={6}> <div ref={doneContainer}></div></Col>
        <Col sm={6}>
        </Col>
      </Row>

    </Container><br></br>

    </div>
  );
};

export default Job;