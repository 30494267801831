import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../images/lala.png';
import biz from '../../images/bizsafe1.png';
import et from '../../images/et.png';
import dp from '../../images/dpp.png';
import basis from '../../images/basis.png';
import biz2 from '../../images/biz2.png';
const Footer = () => {
  return (
    <footer className="bg-dark text-light py-5">
      <Container style={{fontFamily: "montserrat"}}>
        <Row>
          <Col md={7}>
            <img src={logo} style={{ width: "40%" }}></img><br></br>
            <h6 className='mt-4' style={{ fontWeight: "400" }}>About Us</h6>
            <p style={{ fontWeight: "180", fontSize: "12px" }}>At our website, we offer a comprehensive range of services related to commodities. Commodity trading involves buying and selling raw materials or primary agricultural products such as oil, coal, wheat, coffee, rice, sugar and more. These goods play a crucial role in the global economy, and our platform aims to facilitate your engagement with this dynamic market.</p>
          </Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "400" }}>Our Services</h6>
            <ul className="list-unstyled">
              <li>
                <a href="/service" style={{ textDecoration: "none", color: "white", fontWeight: "180", fontSize: "12px" }}>Sugar Supply</a>
              </li>
              <li>
                <a href="/service" style={{ textDecoration: "none", color: "white", fontWeight: "180", fontSize: "12px" }}>Wheat Supply</a>
              </li>
              <li>
                <a href="/service" style={{ textDecoration: "none", color: "white", fontWeight: "180", fontSize: "12px" }}>Oil Supply</a>
              </li>
              <li>
                <a href="/service" style={{ textDecoration: "none", color: "white", fontWeight: "180", fontSize: "12px" }}>Rice Supply</a>
              </li>
            </ul>
          </Col>
          <Col md={2}>
            <h6 style={{ fontWeight: "400" }}>PRINCIPAL OFFICE</h6>
            <ul className="list-unstyled" style={{ fontWeight: "180", fontSize: "12px"}}>
              <li>114 Lavender Street</li>
              <li>#05-54 CT Hub 2</li>
              <li>Singapore 338729</li>
              <li>Phone: +65 62419324, <br></br>+65 62419329, +65 62975359</li>
              <li>Email: info@smartmatrix.life</li>
              <li>Website: www.smartmatrix.</li>
            </ul>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md={5}>
            <h6 style={{ fontWeight: "400" }}>Accrediations</h6>
            <img src={biz2} style={{ width: "20%" }}></img>
            <img src={biz} style={{ width: "20%" }}></img>
            <img src={et} style={{ width: "20%" }}></img>
            <img src={dp} style={{ width: "20%" }}></img>

          </Col>
          <Col md={3}>
          </Col>
          <Col md={3}></Col>

        </Row>
      </Container>
      <div className="text-center mt-4" style={{fontFamily: "montserrat"}}>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="https://www.facebook.com/profile.php?id=100063454840485" className="text-light">
              Facebook
            </a>
          </li>
        </ul>
        <p>&copy; 2023 SmartMatrix PTE Ltd. All rights reserved.</p>
      </div>
    </footer>

  );
};

export default Footer;