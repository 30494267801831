import React from 'react';
import Head from './components/Head/Head';
import UpperAnimation from './components/UpperAnimation/UpperAnimation';
import Midder from './components/Midder/Midder';
import Thirder from './components/Thirder/Thirder';
import Services from './components/Services/Services';
import Service from './components/Services/Service';
import Footer from './components/Footer/Footer';
import Job from './components/Jobs/Job';
import 'typeface-montserrat';
import './global.css';
import 'bootstrap/dist/css/bootstrap.min.css';



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Aboutus from './components/Aboutus/Aboutus';
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';
import ContactUs from './components/ContactUs/ContactUs';

const App = () => {
  return (
    <div>
      <Head></Head>


      <Router>
        <Switch>
          <Route path="/about">
            <Aboutus />
          </Route>


          <Route path="/service">
            <Service></Service>
          </Route>


          <Route path="/career">
            <Job></Job>
          </Route>

          <Route path="/contact">
            <ContactUs></ContactUs>
          </Route>





          <Route exact path="/">
            <Home />
          </Route>


          <Route path="*">
            <NotFound></NotFound>
          </Route>

        </Switch>
      </Router>
      <Footer></Footer>
    </div>
  );
};

export default App;
