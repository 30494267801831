import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../images/commodity.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UpperAnimation = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);


  return (
    <div>
    <Container  className="my-4 fluid">
      <Row className="justify-content-md-center align-items-center" >
        <Col sm={6}>
            <h3 style={{ fontWeight: '680', alignItems: "center", fontFamily: "Montserrat" }}>SMART COMMODITY <span style={{ color: "#45108A"}}>SOLUTIONS</span></h3>
            <p style={{ fontWeight: '300', alignItems: "", fontFamily: "Montserrat", textAlign: "justify" }}><br></br><br></br>

              SMARTMATRIX strives to establish itself as a leading player in the commodity sector by cultivating enduring partnerships with our valued customers and suppliers. Our commitment to accessibility ensures a year-round supply of top-notch products, achieved through collaboration with a diverse range of sellers from around the world. With specialized expertise in the construction, non-renewable energy, and agriculture industries, we cater to a wide range of market needs.<b></b><br></br><br></br>
             
</p>
        </Col>
        <Col sm={6}> <div ref={animationContainer}></div></Col>
      </Row>
      </Container>
    </div>
  );
};

export default UpperAnimation;