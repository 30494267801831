import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../images/404.json';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NotFound = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);
  return (
    <div>
      <Container>
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <div ref={animationContainer}></div>
            <p style={{fontFamily: "montserrat", textAlign: "Center", fontWeight: "600"}}>Opps! Sorry this page doesn't exist!</p><br></br>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;